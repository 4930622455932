import React, { Component,useState } from "react";

import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import data from "../data.json";
import TabThree from "../elements/tab/TabThree";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Background } from "react-parallax";

class PortfolioDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  

  render() {
    
    const { column } = this.props;
    const { tab1, tab2, tab3, tab4, isOpen } = this.state;
    this.state = {
      tab1: 0,
      tab2: 0,
      tab3: 0,
      tab4: 0,
      isOpen: false,
    };
    let isLastOne,
      isFirstOne = false;

       const jobID =
      this.props.location.useParams == undefined
        ? localStorage.getItem("jobID")
        : this.props.location.useParams["id"];

    localStorage.setItem("jobID", jobID);

    const currentID = localStorage.getItem("jobID");
    const id = jobID === undefined ? currentID : jobID;
    const singleProj = data.companies[currentID];
    const name = singleProj.info["name"];
    const role = singleProj.info["role"];
    const desc = singleProj.info["desc"];
    const dir = singleProj.info["dir"];

    const startdate = singleProj.info.startDate;
    const endDate = singleProj.info.endDate;
    const location = singleProj.info.address;
    const projects = singleProj.projects;
    const projIndex = 0;
    const images = projects[projIndex].images;

    const total = data.companies.length;
    const nxtProj = (currentID == total - 1) ? currentID : Number(currentID) + 1;
    const prevProj = (currentID == 0 )? 0 : Number(currentID - 1);

    if (currentID === nxtProj) {
      isLastOne = true;
    }
    if (currentID <= 0) {
      isFirstOne = true;
    }
    function importAll(r) {
      return r.keys().map(r);
    }

    const image = importAll( require.context( "../assets/images/page-header/project-ph/", false, /\.(png|jpe?g|svg)$/ ) );

    const myStyle = {
      backgroundImage: `url(${image[currentID]})`
    };

    return (
      <React.Fragment>
        <PageHelmet pageTitle="Portfolio Details" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image" 
          style={myStyle}
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title">
                  {name}
                  </h2>
                  <p>
                   {role}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <p className="subtitle">Primary Responsibilites</p>
                    <p>{desc}</p>

                    <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <span>Start Date</span>
                        <h4>{startdate}</h4>
                      </div>

                      <div className="port-view">
                        <span>End Date</span>
                        <h4>{endDate}</h4>
                      </div>

                      <div className="port-view">
                        <span>Located</span>
                        <h4>{location}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="portfolio-thumb-inner">
                    <div className="tabs-area">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
 
                            <TabThree
                            project = {projects}
                            tabStyle="col-6"
                            dir = {dir}
                            currentProj={0}
                            >

                            </TabThree>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Related Work */}
        <div className="portfolio-related-work pt--60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="theme-color font--18 fontWeight600">
                    Additional Work
                  </span>
                  <h2>Other Projects</h2>
                </div>
              </div>
            </div>
            <div className="row mt--10 PrevNxtPort">
              {/* Start Single Portfolio */}
              <div
                id="prev"
                className="col-lg-6 col-md-6 col-12"
                style={{ display: isFirstOne ? "none" : "block" }}
              >
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <Link
                      className="thumb"
                      to={{
                        pathname: "/portfolio-details",
                        useParams: { id: prevProj, project:0  },
                      }}
                    >
                      <img src={`${image[prevProj]}`} alt="Portfolio-images" />
                    </Link>
                  </div>
                  <div className="inner">
                    <h4>{data.companies[prevProj].info["name"]}</h4>
                  </div>
                </div>
              </div>

              {/* End Single Portfolio */}
              {/* Start Single Portfolio */}
              {
                <div
                  id="nxt"
                  className="col-lg-6 col-md-6 col-12"
                  style={{ display: isLastOne ? "none" : "block" }}
                >
                  <div className="related-work text-center mt--30">
                    <div className="thumb">
                      <Link
                        className="thumb"
                        to={{
                          pathname: "/portfolio-details",
                          useParams: { id: nxtProj, project:0},
                        }}
                      >
                        <img src={`${image[nxtProj]}`} alt="Portfolio-images" />
                      </Link>
                    </div>
                    <div className="inner">
                      <h4>{data.companies[nxtProj].info["name"]}</h4>
                    </div>
                  </div>
                </div>
              }
              {/* End Single Portfolio */}
            </div>
          </div>
        </div>
        {/* End Related Work */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails;
