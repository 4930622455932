import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import PortfolioList from "../elements/portfolio/PortfolioList";
import data from "../data.json";

const list = [
  {
    category: "Role: Lead UI/UX Designer ",
    title: "Golf Now G1 F&B App",
  }
];
const myStyle = {
  backgroundImage: `url(${require( '../assets/images/portfolio/nbcu/nbcu_gn_08.jpg' )} )`
};

const Portfolio = () => {

  return (
    <>
      <PageHelmet pageTitle="Porfolio" />

      {/* Start Header Area  */}
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Portfolio"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Portfolio Area */}
        <div className="portfolio-area pt--90 pb--140 bg_color--1">
          <div className="rn-slick-dot">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="slick-space-gutter--15 slickdot--20">
                    <Slider>
                      {
                      list.map((value, index) => (
                        <div className="portfolio" key={index}>
                          <div className="thumbnail-inner">
                            <div className={`thumbnail`}   style={myStyle}></div>
                            <div
                              className={`bg-blr-image`}
                            ></div>
                          </div>
                          <div className="content">
                            <div className="inner">
                              <p>{value.category}</p>
                              <h4>
                                <a href="/CaseStudy">{value.title}</a>
                              </h4>
                              <div className="portfolio-button">
                                <a className="rn-btn" href="/CaseStudy">
                                  Case Study
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                      }
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120 bg_color--5">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30">
                    <h2 className="title">All Works</h2>
                    <p>
                      Honestly, building these projects has been like adult
                      Legos on overdrive. Except way more expensive, and
                      slightly more likely to accidentally set off a sonic boom
                      in my office.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  workData={data}
                  styevariation="text-left mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </>
  );
};

export default Portfolio;
