import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Tom Alvarez {this.props.pageTitle}</title>
                    <meta name="description" content="Product Design Manager with 15+ years experience in leading UI/UX initiatives across various high-profile platforms, including SaaS products and AAA game titles. Skilled in overseeing the creation and integration of user-centered designs, ensuring brand consistency and enhancing user engagement. Strong expertise in deploying advanced design tools and methodologies to streamline workflows and improve operational efficiencies. Recognized for exceptional abilities in client relationship management, design strategy implementation, and aligning project outcomes with business objectives." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
