import React, { Component, useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class TabStyleThree extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentProj:0,
      project:0,
      isOpen: false,
      imgPath:''
    };


  }

  render() {

    const { project, tabStyle, dir } = this.props;
    const { currentImg, currentIndex,currentProj, isOpen,imgPath } = this.state;
    ;
    const setImages = event => {
      this.setState({
        currentImg:event.img,
        currentIndex:event.in,
        isOpen: true,
        imgPath:event.imgPath
      });
    };

    const setProj = event => {
      this.setState({
        currentProj:event.currentProj,
      });
    };
    if( this.props.project.length === 1)
    {
        this.state.currentProj=0;
    }

    return (
      <div>
        <Tabs>
        <div className="row text-center" >
            <div className="col-lg-12">
              <div className="tablist-inner">
              
                <TabList
                  className="tab-style--1 text-center portfolio-tab"
                  style={{
                    display: project.length === 1 ? "none": "block",
                  }}
                >
                  {project.map((item, k) => {
                    return (
                      <Tab  onClick={() =>
                        setProj({ currentProj: k })
                      }>
                        <span id={item.abbr}>{item.name}</span>
                      </Tab>
                    );
                  })}
                </TabList>
              </div>
            </div>
          </div>
          {project.map((value, index) => {
            
            return (
              <TabPanel className="row row--35">
                {
                    value.images.map((imgV, ind) => {
                      return (
                        <div className={`${tabStyle}`} id={ind}>
                          {isOpen && (
                            <Lightbox
                              mainSrc={ require( '../../assets/images/portfolio/'+dir+"/"+  project[currentProj].images[currentIndex] ) }
                              nextSrc={
                                project[currentProj].images[ (currentIndex ) % value.images.length ]
                              }
                              prevSrc={
                                value.images[ (currentIndex + value.images.length - 1) % value.images.length]
                              }
                              onCloseRequest={() =>
                                this.setState({ isOpen: false })
                              }
                              onMovePrevRequest={() =>
                                this.setState({
                                  currentIndex: (currentIndex + value.images.length - 1) % value.images.length,
                                })
                              }
                              onMoveNextRequest={() =>
                                this.setState({
                                  currentIndex:(currentIndex + 1) % value.images.length,
                                })
                              }
                              imageLoadErrorMessage="Image Loading ..."
                              enableZoom={false}
                            />
                          )}
                          <div className="item-portfolio-static">
                            <div
                              onClick={event =>{
                                setImages({img:imgV, in:ind, imgPath:event.target.currentSrc});
                                
                                // console.log(event.target.currentSrc)
                               
                              }
                              }
                            >
                              <div className="portfolio-static">
                                <div className="thumbnail-inner">
                                  <div className="thumbnail">
                                    <a href="#portfolio-details">
                                      <img
                                        src={require("../../assets/images/portfolio/" +dir+"/"+ imgV )}
                                        alt={imgV}
                                        key={ind}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                }
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

export default TabStyleThree;
