import React, { Component } from "react";
import { FaLinkedinIn } from "react-icons/fa";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-12">
                <div className="footer-404" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-12 col-sm-12 col-12">
                      <div className="footer-link">
                        <ul className="d-flex justify-content-center even">
                          <li>
                            <a href="/portfolio">Work</a>
                          </li>
                          <li>
                            <a href="/about">About</a>
                          </li>
                          <li>
                            <a href="/contact">Let's Talk</a>
                          </li>
                          <li>
                            <a href="mailto:info@tomealvarez.com">
                              info@tomealvarez.com
                            </a>
                          </li>
                         
                        </ul>
                            <p className="col-12 text-center text-white-50">
                              Copyright © {new Date().getFullYear()} All Rights
                              Reserved.
                            </p>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
