import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  FaLinkedinIn
} from "react-icons/fa";



class Testimonial extends Component {
  
  render() {
    const { data } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
            {
              data.map(item => {
              
              return <>
              <TabPanel>
              <div className="rn-testimonial-content text-center">
                <div className="inner">
                  <p>
                  {item.desc}
                  </p>
                </div>
                <div className="author-info">
                  <h6>
                    <span> {item.name}</span> -  {item.title}
                  </h6>
                </div>
              </div>
            </TabPanel>
            </>
              })
            }
             <TabList className="testimonial-thumb-wrapper">
              {
                  data.map(thum => {
              
                    return <>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={thum.img} alt=  {thum.name} />
                        </div>
                      </div>
                    </Tab>
                  </>
                    })
               
              }
              </TabList>
            </Tabs>
            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="https://www.linkedin.com/in/tomealvarez/recommendations/">  Read More <span>{<FaLinkedinIn />}</span></a>
                                    </div>
                                </div>
                            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;


             