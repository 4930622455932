import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";



class TabsTwo extends Component {
  constructor(props) {
    super(props);
    const skills = this.props;
  }
  render() {
    const { tabStyle } = this.props;
    const tabs = ["Main skills", "Awards", "Experience","Education & Certification"];
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tabs[0]}</Tab>
                    <Tab>{tabs[1]}</Tab>
                    <Tab>{tabs[2]}</Tab>
                    <Tab>{tabs[3]}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>    
                      {
                       this.props.skills.map(item => {
                        return <li> <div class="title">
                          {item.name} <span> - {item.category}</span>
                        </div>
                          {item.desc}</li>
                        })
                      }      
                      </ul>
                    </div>
                  </TabPanel>
               <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                      {
                        this.props.awards.map(item => {
                       
                        return <li> <div class="title">
                          {item.name} <span> - {item.category}</span>
                        </div>
                          {item.desc}</li>
                        })
                      }      
                      </ul>
                    </div>
                  </TabPanel>

                    <TabPanel>
                      <div className="single-tab-content">
                        <ul>
                        {
                          this.props.exp.map(item => {
                          
                          return <li> <div class="title">
                            {item.name} <span> - {item.company}</span>
                          </div>
                            {item.dates}</li>
                          })
                        }      
                        </ul>
                      </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                      {
                        this.props.education.map(item => {
                       
                        return <li> <div class="title">
                          {item.name} <span> - {item.category}</span>
                        </div>
                          {item.dates}</li>
                        })
                      }      
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
