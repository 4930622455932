import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";

class PortfolioList extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { column, styevariation, workData } = this.props;

    function importAll(r) {
      return r.keys().map(r);
    }

    const image = importAll( require.context( "../../assets/images/portfolio/", false, /\.(png|jpe?g|svg)$/ ) );

    return (
      <React.Fragment>
        {
        Object.values(workData.companies).map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div
                  className={`thumbnail`}
                   style={{
                    backgroundImage: `url(${image[index]})`,
                   }}
                ></div>
                <div className={`bg-blr-image `}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>{value.role}</p>
                  <h4>
                    <a href="/portfolio-details">{value.info.name}</a>
                  </h4>
                  <div className="portfolio-button">
                    <Link className="rn-btn" to={{pathname:'/portfolio-details', useParams:{id:index} }}> View Details</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioList;
