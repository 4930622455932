import React, { Component } from "react";

import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class CaseStudy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { column } = this.props;

    const haderInfo = {
      category: "Role: Lead UI/UX Designer ",
      title: "Golf Now G1 F&B App",
    };
    const list = [
      {
        img: "teesheet.png",
        title: "Advanced tee sheet",
      },
      {
        img: "pos.png",
        title: "Powerful point of sale system",
      },
      {
        img: "f&b.png",
        title: "Intuitive food & beverage",
      },
    ];

    const myStyle = {
      backgroundImage: `url(${require("../assets/images/portfolio/nbcu/nbcu_gn_08.jpg")} )`,
    };

    return (
      <React.Fragment>
        <PageHelmet pageTitle="Case Study" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={myStyle}
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title">{haderInfo.title}</h2>
                  <p>{haderInfo.category}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="portfolio-details">
                  <div className="inner">
                    <p className="subtitle">Project Overview</p>
                    <p>
                      GolfNow, a subsidiary of NBC Sports Golf, stands as the
                      world's largest online marketplace for tee times,
                      facilitating seamless connections between golfers and golf
                      courses. I had the opportunity to work on a significant
                      project called G1, which is a cutting-edge SaaS B2B
                      application. Serving as a revenue-driving platform, G1
                      empowers golf course operators to take full control of
                      their operations, managing every facet with ease and
                      efficiency.
                    </p>

                    <div className="portfolio-view-list d-flex flex-wrap">
                      <p className="subtitle col-12">Some of the features</p>
                      {list.map((item, index) => (
                        <>
                          <div className="port-view text-center">
                            <span>{item.title}</span>
                            <img
                              src={require("../assets/images/case_study/" +
                                item.img)}
                              alt={item.title}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="rn-blog-details pb--70 bg_color--1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inner-wrapper">
                        <div className="inner">
                          <h3 className="title">My Role</h3>
                          <p>
                            In my role as the lead designer, I assumed a
                            position of creative leadership, with a primary
                            focus on crafting intuitive and visually appealing
                            UI/UX for a diverse array of SaaS applications. This
                            encompassed overseeing the end-to-end design process
                            for a multitude of projects, which spanned across
                            desktop and mobile platforms, serving both B2B and
                            B2C markets. Noteworthy projects in my extensive
                            portfolio include highly successful ventures like
                            GolfNow Reservations, GolfNow One, and several
                            others. <br />
                            <br />
                            My creative leadership extended beyond design
                            itself, as I have a strong background in mentoring
                            and guiding design teams to reach their full
                            potential. My project management skills played a
                            crucial role in ensuring that milestones and budgets
                            were met, guaranteeing the successful delivery of
                            these notable projects. This comprehensive approach
                            to creative direction, mentoring, and project
                            management allowed for the creation of exceptional
                            user experiences that not only met but exceeded
                            industry standards and expectations.
                          </p>

                          <h3 className="title">User Flow</h3>
                          <p>
                            The diagram provides a visual representation of the
                            user's journey when logging into the app. It
                            illustrates the sequential steps and interactions
                            involved in the login process, offering a clear and
                            concise overview of the user flow. It's worth noting
                            that user flows like this one can be constructed
                            with varying levels of detail and complexity,
                            depending on the specific needs and objectives of
                            the project. <br />
                            <br />
                            In this instance, I opted for a straightforward and
                            simplified user flow design, keeping it focused and
                            to the point. This approach was deliberate to ensure
                            that the key login steps were easily understood and
                            that the user's path to access the app was presented
                            in the most intuitive and user-friendly manner
                            possible. The result is a user flow that effectively
                            communicates the login process without unnecessary
                            complexity, making it accessible and user-centric.
                          </p>
                          <img src={require("../assets/images/case_study/userflow.png")}  alt="user Flow" />
                          <div className="portfolio-view-list d-flex flex-wrap justify-content-center">
                              <h3 className="title mt-5 col-12">Design Toolkit</h3>
                              <div className="port-view text-center order-1  col-4">
                                <p className="text-center">Sketch </p>
                                <img src={require("../assets/images/case_study/icon_sketch.png")}/>
                              </div>
                              <div className="port-view text-center order-2 col-4">
                                <p className="">Photoshop </p>
                                <img src={require("../assets/images/case_study/icon_ps.png")}/>
                              </div>
                              <div className="port-view  text-center order-3 col-4">
                                <p className="">Illustrator </p>
                                <img src={require("../assets/images/case_study/icon_ai.png")}/>
                              </div>
                          </div>
                          
                          <h3 className="title  mt-5 ">Wireframes</h3>
                          <p>
                          In my endeavor to create an engaging F&B application with a user-friendly interface, I dedicated meticulous attention to crafting intricate layouts that encouraged users to rely on intuitive muscle memory, streamlining and expediting the order placement process for enhanced user satisfaction and efficiency. 
                          </p>
                          <img src={require("../assets/images/case_study/wireframes-main.jpg")}  alt="user Flow" />
                          <img className="mt-5" src={require("../assets/images/case_study/wireframes-ranger.jpg")}  alt="user Flow" />
                          <h3 className="title  mt-5 ">Designs</h3>
                          <p>
                          The design showcases a clean and sleek layout, strategically employing generous white space that enhances content readability while exuding an air of sophistication and elegance. With a color palette predominantly composed of muted tones and subtle pastel accents, it artfully evokes a serene and refined ambiance that complements the overall user experience  
                          </p>
                          <img src={require("../assets/images/portfolio/nbcu/nbcu_gn_02.jpg")}  alt="user Flow" />
                          <img className="mt-5" src={require("../assets/images/portfolio/nbcu/nbcu_gn_08.jpg")}  alt="user Flow" />
                          <h3 className="title  mt-5 ">Final Thoughts </h3>
                          <p>
                          I encountered a pivotal scenario where a highly valued customer expressed their intent to discontinue using our application due to dissatisfaction with its previous design. Recognizing the gravity of the situation, I wholeheartedly embraced their feedback, considering it an invaluable source of insights. I embarked on a dedicated journey to overhaul the design, incorporating their suggestions and refining the user experience. Upon the implementation of the enhanced design, the customer's response was overwhelmingly positive. They were genuinely impressed by the design's newfound intuitiveness and aesthetic appeal, which not only secured their continued patronage but also led to their heightened satisfaction. This experience underscored the profound significance of attentively listening to customer feedback and the continuous commitment to design refinement to align with and exceed their evolving needs and expectations. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default CaseStudy;
