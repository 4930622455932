import React, { Component } from 'react';
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";

 class error404 extends Component {
    
    render() {
        return (
            <>
                <Header headerPosition="header--transparent" color="color-white"  />
                {/* Start Page Error  */}
                <div className="error-page-inner bg_color--4 d-flex align-content-center flex-wrap">
                    <div className="container  align-content-center">
                        <div className="row">
                            <div className="col-lg-12 d-flex align-items-center">
                                <div className="inner col-12">
                                    <h1 className="title theme-gradient col-12">404!</h1>
                                    <h3 className="sub-title">Page not found</h3>
                                    <span>The page you were looking for could not be found.</span>
                                    <div className="error-button">
                                        <a className="rn-button-style--2 btn-solid" href="/">Back To Homepage</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

                
                <Footer /> 
            </>
        )
    }
}
export default error404;
